import { mergeSxProps, Text, type TextProps } from "@clipboard-health/ui-react";
import { Box, type DialogProps, Fade, type SxProps, type Theme } from "@mui/material";
import { useEffect, useState } from "react";

export interface LoadingTextAnimationProps extends Omit<TextProps, "color" | "children" | "sx"> {
  /**
   * Array of text strings to cycle through
   */
  texts: string[];
  /**
   * Interval in milliseconds between text changes
   * @default 2000
   */
  interval?: number;
  /**
   * Color of the text
   */
  color?: (theme: Theme) => string | undefined;
  /**
   * Additional styles for the text
   */
  textSx?: SxProps<Theme>;
  /**
   * Transition component to use for the animation
   * @default Fade
   */
  TransitionComponent?: DialogProps["TransitionComponent"];
  /**
   * Timeout for the transition component
   */
  transitionTimeout?: number;
}

export function LoadingTextAnimation(props: LoadingTextAnimationProps) {
  const {
    texts,
    interval = 5000,
    color,
    textSx,
    TransitionComponent = Fade,
    transitionTimeout,
    ...textProps
  } = props;

  const [currentIndex, setCurrentIndex] = useState(0);

  // Find index of longest text
  const longestTextIndex = texts.indexOf([...texts].sort((a, b) => b.length - a.length)[0]);

  useEffect(() => {
    if (texts.length <= 1) {
      return undefined;
    }

    const timer = setInterval(() => {
      setCurrentIndex((previous) => (previous + 1) % texts.length);
    }, interval);

    return () => {
      clearInterval(timer);
    };
  }, [texts, interval]);

  if (texts.length === 0) {
    return null;
  }

  if (texts.length === 1) {
    return (
      <Text color={color} sx={textSx} {...textProps}>
        {texts[0]}
      </Text>
    );
  }

  return (
    <Box sx={{ position: "relative" }}>
      {texts.map((text, index) => (
        <TransitionComponent
          key={text}
          appear
          in={index === currentIndex}
          timeout={transitionTimeout}
        >
          <Box
            sx={{
              position: index === longestTextIndex ? "relative" : "absolute",
              inset: 0,
              width: "100%",
              opacity: index === currentIndex ? 1 : 0,
            }}
          >
            <Text
              color={color}
              sx={mergeSxProps({ textWrap: "balance", textAlign: "center" }, textSx)}
              {...textProps}
            >
              {text}
            </Text>
          </Box>
        </TransitionComponent>
      ))}
    </Box>
  );
}
