import { Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Box, Skeleton, Stack } from "@mui/material";
import { useHistory } from "react-router-dom";

import { IconButton } from "./IconButton";

interface PageHeaderTopRowProps {
  rightAction?: React.ReactNode;
  title?: string;
  isLoading?: boolean;
  onClickBack?: () => void;
}

export function PageHeaderTopRow(props: PageHeaderTopRowProps) {
  const { title, isLoading, onClickBack, rightAction } = props;

  const history = useHistory();

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      spacing={6}
      px={7}
      pt={3}
      pb={5}
    >
      <Box sx={{ flexGrow: 1, flexBasis: 0 }}>
        <IconButton
          invert
          iconType="arrow-left"
          variant="contained"
          size="small"
          sx={(theme) => ({ boxShadow: theme.shadows[2] })}
          aria-label="Go back"
          onClick={() => {
            if (isDefined(onClickBack)) {
              onClickBack();
            } else {
              history.goBack();
            }
          }}
        />
      </Box>

      {isDefined(title) && (
        <Text semibold variant="body2" align="center" sx={{ textWrap: "balance" }}>
          {title}
        </Text>
      )}

      {isLoading && !isDefined(title) && (
        <Stack flex={1} height={(theme) => theme.spacing(8)}>
          <Skeleton variant="text" height="100%" />
        </Stack>
      )}

      <Stack direction="row" sx={{ flexGrow: 1, flexBasis: 0, justifyContent: "flex-end" }}>
        {rightAction}
      </Stack>
    </Stack>
  );
}
