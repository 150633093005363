import { mergeSxProps, Text } from "@clipboard-health/ui-react";

import { Button, type ButtonProps } from "./Button";

export interface PickerButtonProps
  extends Omit<ButtonProps, "children" | "endIconType" | "variant"> {
  primaryText: string;
  secondaryText?: string;
}

export function PickerButton(props: PickerButtonProps) {
  const { primaryText, secondaryText, sx, ...restProps } = props;

  return (
    <Button
      variant="outlinedHeavy"
      endIconType="chevron-down"
      sx={mergeSxProps(
        (theme) => ({
          borderRadius: theme.borderRadius?.xLarge,
          gap: 2,
          py: 3,
          paddingLeft: 4,
          paddingRight: 5,
          justifyContent: "flex-start",

          "& > :last-child": {
            marginLeft: "auto",
          },

          ".MuiButton-startIcon": {
            marginRight: 0,

            "& svg": {
              width: theme.size?.iconButton.medium.iconSize,
              height: theme.size?.iconButton.medium.iconSize,
            },
          },
        }),
        sx
      )}
      {...restProps}
    >
      <Text semibold variant="body2">
        {primaryText}
      </Text>

      {secondaryText && (
        <Text variant="caption" color={(theme) => theme.palette.text.secondary}>
          {secondaryText}
        </Text>
      )}
    </Button>
  );
}
