import { mergeSxProps, type TextProps } from "@clipboard-health/ui-react";
import { Skeleton, type SxProps, type Theme } from "@mui/material";

interface TextSkeletonProps {
  variant?: TextProps["variant"];
  width?: string;
  sx?: SxProps<Theme>;
}

export function TextSkeleton(props: TextSkeletonProps) {
  const { variant = "caption", width = "15ch", sx } = props;

  return (
    <Skeleton
      variant="text"
      data-testid="text-skeleton"
      sx={mergeSxProps(
        {
          width,
          fontSize: (theme) => theme.typography[variant].fontSize,
          lineHeight: (theme) => theme.typography[variant].lineHeight,
        },
        sx
      )}
    />
  );
}
