import { mergeSxProps } from "@clipboard-health/ui-react";
import { type TextVariant } from "@clipboard-health/ui-react/src/Typography/Text/Text";
import { ButtonBase, type ButtonBaseProps } from "@mui/material";

import { ButtonInternalLink } from "./ButtonInternalLink";

export const TEXT_BUTTON_SIZES = ["small", "medium", "large"] as const;
export type TextButtonSize = (typeof TEXT_BUTTON_SIZES)[number];

export interface TextButtonProps extends ButtonBaseProps {
  size?: TextButtonSize;
  href?: string;
}

function textPropsBySize(size: TextButtonSize): TextVariant {
  switch (size) {
    case "small": {
      return "caption";
    }

    case "large": {
      return "body1";
    }

    default: {
      return "body2";
    }
  }
}

/**
 * A button that renders like a text link
 * NOTE: Provide `href` to make the button render as an anchor tag. It will support react-router redirecs
 * This is implemented as a separate component rather than a Button variant since it has distinct visual styling
 * and sizing requirements that would be difficult to match with the standard Button variants
 */
export function TextButton(props: TextButtonProps) {
  const { size = "medium", disabled, sx, ...restProps } = props;

  return (
    <ButtonBase
      {...restProps}
      disabled={disabled}
      LinkComponent={ButtonInternalLink}
      sx={mergeSxProps((theme) => {
        return {
          color: disabled
            ? theme.palette.intent?.disabled.text
            : theme.palette.intent?.neutral.text,
          display: "inline-block",
          fontSize: theme.typography[textPropsBySize(size)].fontSize,
          fontWeight: theme.typography.fontWeightMedium,
          height: "1.5rem",
          width: "fit-content",
          borderRadius: 0,
          borderBottom: `1px solid ${String(
            disabled ? theme.palette.intent?.disabled.border : theme.palette.border?.primary
          )}`,

          "@media (hover: hover)": {
            "&:hover": {
              borderBottomColor: theme.palette.border?.primaryInvert,
            },
          },

          "&:active": {
            borderBottomColor: theme.palette.border?.primaryInvert,
          },
        };
      }, sx)}
    />
  );
}
