import { Clipboard } from "@capacitor/clipboard";
import { isDefined } from "@clipboard-health/util-ts";
import { DialogContent, Stack } from "@mui/material";

import { BottomSheet } from "../BottomSheet";
import { Button } from "../Button";
import { DialogFooter } from "../DialogFooter";
import { useOpenGeoLocationContext } from "./useOpenGeoLocation";

export function OpenGeoLocationBottomSheet() {
  const { modalState, appleMapsHref, googleMapsHref, formattedAddress } =
    useOpenGeoLocationContext();

  return (
    <BottomSheet
      modalState={modalState}
      footer={
        <DialogFooter
          orientation="vertical"
          onClose={() => {
            modalState.closeModal();
          }}
        />
      }
    >
      <DialogContent sx={{ padding: 5, pb: 0 }}>
        <Stack spacing={4}>
          <Button
            variant="outlined"
            href={googleMapsHref}
            size="large"
            onClick={(event) => {
              event.stopPropagation();
              modalState.closeModal();
            }}
          >
            Open in Google Maps
          </Button>
          <Button
            variant="outlined"
            href={appleMapsHref}
            size="large"
            onClick={(event) => {
              event.stopPropagation();
              modalState.closeModal();
            }}
          >
            Open in Apple Maps
          </Button>
          {isDefined(formattedAddress) && (
            <Button
              variant="outlined"
              size="large"
              onClick={async (event) => {
                event.stopPropagation();
                await Clipboard.write({ string: formattedAddress });
                modalState.closeModal();
              }}
            >
              Copy Address
            </Button>
          )}
        </Stack>
      </DialogContent>
    </BottomSheet>
  );
}
