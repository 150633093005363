import { Rating as MuiRating, type RatingProps as MuiRatingProps } from "@mui/material";

import { CbhIcon, type IconSize } from "./CbhIcon";

interface RatingProps extends Omit<MuiRatingProps, "icon" | "emptyIcon"> {}

const ratingSizeToIconSize: Record<Required<MuiRatingProps>["size"], IconSize> = {
  small: "xSmall",
  medium: "medium",
  large: "large",
};

export function Rating(props: RatingProps) {
  const { size = "medium", ...restProps } = props;

  return (
    <MuiRating
      {...restProps}
      icon={<CbhIcon type="star-filled" size={ratingSizeToIconSize[size]} />}
      emptyIcon={<CbhIcon type="star" size={ratingSizeToIconSize[size]} />}
    />
  );
}
