import { Slider as MuiSlider, type SliderProps as MuiSliderProps } from "@mui/material";

export interface SliderProps extends Omit<MuiSliderProps, "thumb"> {
  thumb?: boolean;
}

export function Slider(props: SliderProps) {
  const { thumb = true, ...rest } = props;

  return <MuiSlider {...rest} thumb={thumb ? "true" : undefined} />;
}
