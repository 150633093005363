import { useModalState } from "@clipboard-health/ui-react";
import { isAndroidPlatform } from "@src/appV2/lib";
import { type Location } from "@src/appV2/lib/GoogleMaps";
import constate from "constate";

import { constructAppleMapUrl } from "./constructAppleMapUrl";
import { constructGoogleMapUrl } from "./constructGoogleMapUrl";

interface OpenGeoLocationProps {
  location: Location | undefined;
  formattedAddress?: string;
  name?: string;
}

/**
 * Opening map on actual device differs from Android and iOS.
 * Since there is no way to natively open preferred map app on iOS, we use OpenGeoLocationBottomSheet
 * to let the user choose between Google Maps and Apple Maps.
 * On Android, we can open the map directly by providing the `href` to the google maps app.
 *
 * This hook returns both `href` and `onClick` handlers that you can assign to your button.
 * Depending on the platform it will either assign href to the maps url or set up an onClick handler
 * to open the bottom sheet.
 *
 * @example Usage:
 *   const { href, onClick } = useOpenGeoLocationContext();
 *
 *   return <Button href={href} onClick={onClick} />;
 */
export function useOpenGeoLocation(props: OpenGeoLocationProps) {
  const { location, formattedAddress, name } = props;
  const modalState = useModalState();

  if (!location) {
    return { googleMapsHref: undefined, appleMapsHref: undefined, modalState, onClick: undefined };
  }

  const googleMapsHref = constructGoogleMapUrl({ location, formattedAddress });
  const appleMapsHref = constructAppleMapUrl({ location, formattedAddress, name });

  let href: string | undefined;
  let onClick: (() => void) | undefined;

  if (isAndroidPlatform()) {
    href = googleMapsHref;
    onClick = undefined;
  } else {
    href = undefined;
    onClick = () => {
      modalState.openModal();
    };
  }

  return { googleMapsHref, appleMapsHref, href, modalState, onClick, formattedAddress };
}

export const [OpenGeoLocationProvider, useOpenGeoLocationContext] = constate(useOpenGeoLocation);
